import AMapLoader from '@amap/amap-jsapi-loader'

const mapInfo = {
  key: '19da3567642017c4e1644b052ea555ed', // 申请好的Web端开发者Key，首次调用 load 时必填
  version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  plugins: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Marker',
    'AMap.Geolocation',
    'AMap.Geocoder',
    'AMap.ControlBar'
  ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
}

export function initMap(center) {
  return new Promise((resolve, reject) => {
    AMapLoader.load(mapInfo)
      .then(AMap => {
        const map = new AMap.Map('container', {
          zoom: 15, //级别
          center: center || [120.201846, 30.185021],
          viewMode: '2D' //使用2D视图
        })
        resolve({ map, AMap })
      })
      .catch(e => reject(e))
  })
}

export function setCenter (map, center) {
  if (!map) return
  map.setCenter(center)
  map.setZoom(15)
}